import * as apiHelper from './ApiHelper'

const API_PATH = '/api';
const PARTICIPANT_PATH = `${API_PATH}/participant`;
const PROFILE_PATH = `${API_PATH}/participant`;
const MATCHING_PATH = `${API_PATH}/matching`;
const QUESTIONS_PATH = `${API_PATH}/question`;
const SSE_PATH = `${API_PATH}/sse`;
const ADMIN_PATH = `${API_PATH}/admin`;
const HOST = process.env.REACT_APP_API_HOST;

export const voleeApi = {
    auth: {
        login: (body, params) => apiHelper.post(`${HOST}${API_PATH}/auth/login`, params, body),
        loginGoogle: (body, params) => apiHelper.post(`${HOST}${API_PATH}/auth/login/google`, params, body),
        registerAdmin: (body, secret) => apiHelper.post(`${HOST}${API_PATH}/auth/register-admin`, { secret }, body),
        registerUser: (body) => apiHelper.post(`${HOST}${API_PATH}/auth/register-user`, null, body),
        recovery: (body, params) => apiHelper.post(`${HOST}${API_PATH}/auth/recovery`, params, body),
        confirmRecovery: (body, params) => {
            return apiHelper.post(`${HOST}${API_PATH}/auth/confirm-recovery`, params, body)
        },
        resendRecoveryEmail: () => apiHelper.post(`${HOST}${API_PATH}/auth/confirm-email`),
        magicAuth: (body, params) => apiHelper.post(`${HOST}${API_PATH}/auth/auth-magic`, params, body),
        agreePolicy: () => apiHelper.post(`${HOST}${API_PATH}/auth/agree-policy`),
        otp: {
            challenge: (body, params) => apiHelper.post(`${HOST}${API_PATH}/auth/otp/challenge`, params, body),
            validate: (body, params) => apiHelper.post(`${HOST}${API_PATH}/auth/otp/validate`, params, body),
        },
        acceptInvite: (inviteEncoded) => apiHelper.post(`${HOST}${API_PATH}/invitation/${inviteEncoded}`)
    },
    users: {
        delete: (userId, body) => apiHelper.del(`${HOST}${API_PATH}/users/delete/${userId}`, null, body),
    },
    community: {
        create: (body, params) => apiHelper.post(`${HOST}${API_PATH}/community/create`, params, body),
        get: (communityId) => apiHelper.get(`${HOST}${API_PATH}/community/get-by-id`, {
            communityId,
        }),
        getByDomain: (domain) => apiHelper.get(`${HOST}${API_PATH}/community/get-by-domain`, { domain }),
        getByUserId: (userId) => apiHelper.get(`${HOST}${API_PATH}/community/get-by-user-id`, { userId }),
        participants: (communityId, page, search) =>
            apiHelper.get(`${HOST}${API_PATH}/community/${communityId}/participants-list`, { page, search }),
        participantsPreview: (communityId, page, search) =>
            apiHelper.get(`${HOST}${API_PATH}/community/${communityId}/preview/participants`, { page, search }),
        participantsAdmin: (communityId, page, search) =>
            apiHelper.get(`${HOST}${API_PATH}/community/${communityId}/participants`, { page, search }),
        charts: (communityId) => apiHelper.get(`${HOST}${API_PATH}/charts/data`, { communityId }),
        distributions: (communityId) => apiHelper.get(`${HOST}${API_PATH}/charts/distributions`, { communityId }),
        notifications: {
            notifyAllViaEmail: (communityId, body) => apiHelper.post(`${HOST}${API_PATH}/community/${communityId}/participants/notify`, null, body),
            notifyAllViaTelegram: (communityId, body) => apiHelper.post(`${HOST}${API_PATH}/community/${communityId}/participants/notify/telegram`, null, body),
            recommendContactsViaEmail: (communityId, body) => apiHelper.post(`${HOST}${API_PATH}/community/${communityId}/participants/notify/recommended-contacts`, null, body),
            recommendContactsViaTelegram: (communityId, body) => apiHelper.post(`${HOST}${API_PATH}/community/${communityId}/participants/notify/telegram/recommended-contacts`, null, body),
            telegramGroupNotification: (communityId, body) => apiHelper.post(`${HOST}${API_PATH}/community/${communityId}/participants/notify/telegram/group/image`, null, body),
        },
        topTags: (communityId) => apiHelper.get(`${HOST}${API_PATH}/community/${communityId}/top-tags`),
        locations: (communityId) => apiHelper.get(`${HOST}${API_PATH}/community/${communityId}/participants/locations`),
    },
    contact: {
        get: () => apiHelper.get(`${HOST}${API_PATH}/contact`)
    },
    general: {
        init: (body, params) => apiHelper.post(`${HOST}${API_PATH}/init`, params, body, null),
        getHost: () => { return HOST },
        currentUser: () => apiHelper.get(`${HOST}/current-user`),
        saveLangChoice: (lang) => apiHelper.post(`${HOST}/current-user/lang`, null, lang, {
            "Content-Type": "text/plain"
        }),
        subscribeToWebPush: (subscription) => apiHelper.post(`${HOST}/current-user/web-push`, null, subscription),
        notifications: {
            get: () => apiHelper.get(`${HOST}${API_PATH}/notifications`),
            seen: (ids) => apiHelper.post(`${HOST}${API_PATH}/notifications/seen`, null, ids),
            dismiss: (ids) => apiHelper.post(`${HOST}${API_PATH}/notifications/dismiss`, null, ids),
            hasNewerThan: (mostResentId) => apiHelper.get(`${HOST}${API_PATH}/notifications/${mostResentId}/newer`),
        }
    },
    participant: {
        create: (body, userId) => apiHelper.post(`${HOST}${PARTICIPANT_PATH}/create`, { userId }, body),
        myAnswers: (participantId) => apiHelper.get(`${HOST}${PARTICIPANT_PATH}/my-answers`, { participantId }),
        updateAnswers: (participantId, newAnswers) => apiHelper.post(`${HOST}${PARTICIPANT_PATH}/update-answers`, { participantId }, newAnswers),
        myCommunities: (userId) => apiHelper.get(`${HOST}${PARTICIPANT_PATH}/my-communities`, { userId }),
        getProfile: (participantId) => apiHelper.get(`${HOST}${PARTICIPANT_PATH}/profile`, { participantId }),
        resolveSlug: (slug) => apiHelper.get(`${HOST}${PARTICIPANT_PATH}/profile/${slug}`),
        updateLookingFor: (participantId, lookingForText) => apiHelper.post(`${HOST}${PARTICIPANT_PATH}/update-looking-for`, {
            participantId,
        }, lookingForText),
        updateProfile: (profile) => apiHelper.post(`${HOST}${PARTICIPANT_PATH}/update-profile`, null, profile),
        updateNetworkingPref: (participantId, networkingEnabled) => apiHelper.post(`${HOST}${PARTICIPANT_PATH}/update-networking-pref`, { participantId, networkingEnabled }),
        updateNotificationPref: (contactType, disable) => apiHelper.post(`${HOST}${PARTICIPANT_PATH}/update-notification-pref`, { contactType, disable }),
        linkedInSearch: (linkedInProfileUrl) => apiHelper.get(`${HOST}${PARTICIPANT_PATH}/search-linkedin`, { linkedInProfileUrl }),
        telegramConnectionSSE: (userId) => apiHelper.sseEventSource(`${HOST}${SSE_PATH}/telegram-connect?userId=${userId}`),
        tutorialSeen: (participantId) => apiHelper.post(`${HOST}${PARTICIPANT_PATH}/tutorial-seen`, { participantId }),
    },
    credits: {
        // get: () => apiHelper.get(`${HOST}${API_PATH}/credits`),
        get: () => Promise.resolve({ "INTERESTED_CLICK": 10 }),
    },
    bookmarks: {
        get: (page = 0) => apiHelper.get(`${HOST}${API_PATH}/bookmarks`, { page }),
        add: (participantId) => apiHelper.post(`${HOST}${API_PATH}/bookmarks/${participantId}`),
        remove: (participantId) => apiHelper.del(`${HOST}${API_PATH}/bookmarks/${participantId}`),
    },
    relations: {
        get: (page = 0) => apiHelper.get(`${HOST}${API_PATH}/relations`, { page }),
        add: (participantId, subjectId, like, note) => apiHelper.post(`${HOST}${API_PATH}/relations`, null, {
            participantId: participantId,
            subjectId: subjectId,
            relationType: like ? "LIKE" : "DISLIKE",
            note: note
        }),
    },
    requests: {
        get: (page = 0) => apiHelper.get(`${HOST}${API_PATH}/requests`, { page }),
        answer: (requestId, answer) => apiHelper.post(`${HOST}${API_PATH}/requests/${requestId}/answer`, null, { answer }),
    },
    profile: {
        profileCompletion: (participantId) => apiHelper.get(`${HOST}${PROFILE_PATH}/${participantId}/profile-completion`),
    },
    matching: {
        matchesHistory: (page = 0, participantId) => apiHelper.get(`${HOST}${MATCHING_PATH}/matches-history`, {
            participantId,
            page,
        }),
        calcRelevanceRate: () => apiHelper.post(`${HOST}${MATCHING_PATH}/calc-relevance-rate`),
        getRelevanceRate: (participantId) => apiHelper.get(`${HOST}${MATCHING_PATH}/get-relevance-rate`, { participantId }),
        getInstantMatching: (page, status)=> apiHelper.get(`${HOST}${MATCHING_PATH}/instant`, { page, status }),
        updateScore: (matchingId, score) => apiHelper.post(`${HOST}${MATCHING_PATH}/update-score`, null, {
            matchingId,
            score,
        }),
        updateNote: (matchingId, note) => apiHelper.post(`${HOST}${MATCHING_PATH}/update-note`, null, {
            matchingId,
            note,
        }),
        sendIntro: (from, matchingResultId, introMessage) => apiHelper.post(`${HOST}${MATCHING_PATH}/send-intro`, null, {
            from,
            matchingResultId,
            introMessage
        }),
        pendingScore: (participantId) => apiHelper.get(`${HOST}${MATCHING_PATH}/${participantId}/pending-score`),
        requests: (page = 0) => apiHelper.get(`${HOST}${MATCHING_PATH}/requests`, { page }),
    },
    question: {
        get: (communityId) => apiHelper.get(`${HOST}${QUESTIONS_PATH}/get-by-community`, { communityId })
    },
    images: {
        uploadLink: (imageUrl) => apiHelper.post(`${HOST}${API_PATH}/images/upload/link`, null, { imageUrl }),
        upload: (image) => apiHelper.post(`${HOST}${API_PATH}/images/upload`, null, image),
        delete: () => apiHelper.del(`${HOST}${API_PATH}/images/remove`)
    },
    landing: {
        get: (communityDomain, langSuffix = "") => apiHelper.get(`/community/${communityDomain}/landing${langSuffix.toLowerCase()}.json`, null, {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0',
        }),
    },
    chats: {
        get: (communityId, page = 0) => apiHelper.get(`${HOST}${API_PATH}/chats`, { communityId, page }),
        // unread: () => apiHelper.get(`${HOST}${API_PATH}/chats/unread`),
        unread: () => Promise.resolve(3),
    },
    telegram: {
        connect: (body) => apiHelper.post(`${HOST}${API_PATH}/telegram/connect`, null, body),
    },
    admin: {
        nextMatch: (communityId) => apiHelper.get(`${HOST}${ADMIN_PATH}/${communityId}/matches/next`),
        history: (pairId) => apiHelper.get(`${HOST}${ADMIN_PATH}/pairs/${pairId}/history`),
        assistance: (pairId) => apiHelper.get(`${HOST}${ADMIN_PATH}/pairs/${pairId}/score-assistance`),
        setScore: (pairId, internalAdminScore) => apiHelper.post(`${HOST}${ADMIN_PATH}/pairs/${pairId}/score`, { internalAdminScore }),
        activeParticipantsCSV: (communityId) => apiHelper.get(`${HOST}${ADMIN_PATH}/stats/community/${communityId}/participants`),
        participants: (communityId, page, search, profiles) => apiHelper.get(`${HOST}${ADMIN_PATH}/${communityId}/participants`, { page, search, profiles }),
        notify: (participantId, body) => apiHelper.post(`${HOST}${ADMIN_PATH}/participants/${participantId}/notify`, null, body),
        toggleProfile: (participantId, shouldDisable) => apiHelper.post(`${HOST}${ADMIN_PATH}/participants/${participantId}/profile/toggle`, { shouldDisable }),
        toggleNetwork: (participantId, shouldDisable) => apiHelper.post(`${HOST}${ADMIN_PATH}/participants/${participantId}/networking/toggle`, { shouldDisable }),
    },
    constants: {
        contacts: {
            types: () => apiHelper.get(`${HOST}${API_PATH}/constants/contacts/types`)
        }
    }
};
